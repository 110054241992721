<template>
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <div class="is-flex is-justify-content-space-between">
          <p class="modal-card-title">New Collection Request</p>
        </div>
      </header>
      <form @submit.prevent="">
        <section class="modal-card-body">
          <!-- collection date - next 7 days only -->
          <div class="field">
            <label class="label">Request Date</label>
            <div class="select control">
              <select v-model="requestDate" required>
                <option
                  :value="date.timestamp"
                  v-for="date in dateList"
                  :key="date.date"
                >
                  {{ date.date }}
                </option>
              </select>
            </div>
          </div>
          <div class="field">
            <label class="label">Requested By</label>
            <input
              v-model="requestBy"
              type="text"
              class="control input"
              placeholder="Enter your full name"
              required
            />
          </div>
          <div class="field mt-5">
            <label class="label">Additional Requirments</label>
            <div class="control">
              <label class="checkbox ml-5">
                <input type="checkbox" v-model="replaceContainer" /> Replace
                Container
              </label>
              <label class="checkbox ml-5">
                <input type="checkbox" v-model="additionalConatiner" />
                Additional Container
              </label>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-success"
            :class="{ 'is-loading': isPending }"
            @click="handleClick"
          >
            Create
          </button>
          <button class="button" @click="closeModal">Cancel</button>
        </footer>
      </form>
    </div>
  </div>
</template>

<script>
import useCollection from "@/composables/useCollection";
import { computed, ref, watchEffect } from "vue";
import { doc, onSnapshot, Timestamp } from "@firebase/firestore";
import { db } from "@/firebase/config";

export default {
  props: ["showModal", "supplierid", "locationid"],
  setup(props, context) {
    const { addDoucment, error, isPending } =
      useCollection("collectionRequests");

    // Getting a ref to the document
    let docRef = doc(db, "suppliers", props.supplierid);

    // supplier info
    const supplier = ref(null);
    const locationInfo = ref(null);

    const requestBy = ref("");

    // Additional Requiremnets
    const replaceContainer = ref(false);
    const additionalConatiner = ref(false);

    const estimatedVolume = ref(null);

    // Date fields
    const requestDate = ref(null);
    const dateList = ref([]);

    async function fetchDates(supplierId, locationId) {
      try {
        const apiUrl =
          "https://nf-oms-api.neutralfuels.net" +
          "/getSupplierLocationPlannerData/" +
          supplierId +
          "/" +
          locationId;

        const response = await fetch(apiUrl);

        if (response.ok) {
          const isoDates = await response.json();
          console.debug(isoDates);

          const today = new Date();
          today.setHours(0, 0, 0, 0); // Set time to 00:00:00 to match with the date from the API

          dateList.value = isoDates
            .map((isoDate) => {
              const date = new Date(isoDate);
              return {
                date: date.toDateString(),
                timestamp: Timestamp.fromDate(date),
              };
            })
            .filter(
              (dateObj) => new Date(dateObj.date).getTime() !== today.getTime()
            );
        }
      } catch (err) {
        console.error("Error fetching dates:", err);
      }
    }

    watchEffect(() => {
      if (props.locationid != "0") {
        fetchDates(props.supplierid, props.locationid);
      } else {
        fetchDates(props.supplierid, 0);
      }
    });

    // Close modal
    const closeModal = () => {
      context.emit("close");
    };

    // Create collection request
    const handleClick = async () => {
      const collectionRequest = {
        additionalContainer: additionalConatiner.value,
        countryid: supplier.value.countryid,
        createdAt: Timestamp.now(),
        createdBy: "Merchant Application",
        geotag: locationInfo.value
          ? locationInfo.value.areaname
          : supplier.value.geotag,
        highPriority: false,
        locationId: locationInfo.value ? parseInt(props.locationid) : 0,
        locationName: locationInfo.value
          ? locationInfo.value.locationname
          : null,
        modifiedAt: Timestamp.now(),
        orderType: "COLLECTION",
        replaceContainer: replaceContainer.value,
        requestedBy: requestBy.value,
        requestedDate: requestDate.value,
        scheduledDate: null,
        siteId: supplier.value.siteid,
        status: "REQUESTED",
        supplierId: props.supplierid,
        supplierName: supplier.value.suppliername,
        estimatedVolume: estimatedVolume.value,
      };

      console.log(collectionRequest);
      if (requestDate.value && requestBy.value) {
        await addDoucment(collectionRequest);
        if (!error.value) {
          requestBy.value = null;
          requestDate.value = null;
          closeModal();
        }
      }
    };

    const unsub = onSnapshot(
      docRef,
      (doc) => {
        if (doc.data()) {
          if (props.locationid != "0") {
            supplier.value = { ...doc.data() };
            console.log("Data Received: ", doc.data());
            const locList = supplier.value.locations;
            locList.forEach((loc) => {
              if (loc.locationid == parseInt(props.locationid)) {
                locationInfo.value = loc;
                if (loc.containers) {
                  var vol = 0;
                  loc.containers.forEach((container) => {
                    vol = vol + container.maxVolume * container.quantity;
                  });

                  estimatedVolume.value = vol;
                } else {
                  estimatedVolume.value = 150;
                }
              }
            });
          } else {
            supplier.value = { ...doc.data() };
            if (doc.data().containers) {
              var vol = 0;
              doc.data().containers.forEach((container) => {
                vol = vol + container.maxVolume * container.quantity;
              });

              estimatedVolume.value = vol;
            } else {
              estimatedVolume.value = 150;
            }
            // console.log('Data Received: ', doc.data())
          }
        } else {
          console.log("That docuemnt does not exsist");
        }
      },
      (err) => {
        console.log(err.message);
        supplier.value = null;
      }
    );

    watchEffect((onInvalidate) => {
      // unsubscribe from the realtime listner
      onInvalidate(() => unsub());
    });

    return {
      closeModal,
      isPending,
      handleClick,
      dateList,
      additionalConatiner,
      replaceContainer,
      requestBy,
      requestDate,
    };
  },
};
</script>

<style>
</style>