<template>
  <div class="modal" :class="{ 'is-active': showModal}">
    <div class="modal-background"></div>
    <div class="modal-content">
        <article class="message is-success is-medium">
            <div class="message-header">
                <p>Contact Us</p>
                <button class="delete is-medium" @click="closeModal"></button>
            </div>
            <div class="message-body">
                <p class="is-5 title mb-6">
                    If you have any issues with your order, please feel free to contact us.
                </p>
                <p class="is-5 subtitle">
                    <b>Mobile: </b> +971 (50) 954 3119
                    <br>
                    <b>Email: </b> uco@neutralfuels.com
                </p>
            </div>
        </article>
    </div>
  </div>
</template>

<script>
export default {
    props: [ 'showModal'],
    setup(props, context) {
        // Close modal
        const closeModal = () => {
            context.emit('close')
        }

        return { closeModal }
    }
}
</script>

<style>

</style>