<template>
  <div class="section">
    <div class="content has-text-centered">
      <p>
        <strong>Neutral Fuels Merchant</strong> developed by
        <a href="https://neutralfuels.com">Neutral Fuels LLC</a>.
      </p>

      <p>ⓒ 2022 Neutral Fuels LLC</p>
      <p>V 1.2.4</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>