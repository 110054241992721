<template>
  <div class="contatiner has-text-centered">
      <div class="box" v-if="supplier">
        <div class="has-text-left" :class="{ 'mb-3': hasLocation}">
          <h3 class="is-4 title">{{ supplier.suppliername }}</h3>
        </div>
        <div class="has-text-left" v-if="hasLocation">
          <h3 class="is-5 subtitle">{{locationInfo.locationname}}</h3>
        </div>
      </div>
    </div>
</template>

<script>
import { db } from '@/firebase/config';
import { doc, onSnapshot } from '@firebase/firestore';
import { ref, watchEffect } from 'vue';
export default {
    props: [ 'locationid', 'supplierid' ],
    setup(props, context) {
      const supplier = ref(null)
      const error = ref(null)
      const hasLocation = ref(false)
      const locationInfo = ref(null)

      // Getting a ref to the document
      let docRef = doc(db, 'suppliers', props.supplierid)

      const unsub = onSnapshot(docRef, doc => {
          if (doc.data()) {
              if (props.locationid != "0") {
                supplier.value = { ...doc.data() }
                error.value = null
                console.log('Data Received: ', doc.data())
                const locList = supplier.value.locations
                locList.forEach(loc => {
                  if (loc.locationid == parseInt(props.locationid)) {
                    locationInfo.value = loc
                    hasLocation.value = true
                  }
                })
              } else {
                hasLocation.value = false
                supplier.value = { ...doc.data() }
                error.value = null
                console.log('Data Received: ', doc.data())
              }
              
          } else {
              // console.log('That docuemnt does not exsist')
              error.value = 'That docuemnt does not exsist'
              context.emit('missingSupplier')
          }
      }, (err) => {
          console.log(err.message)
          error.value = 'Could not fetch the document'
          supplier.value = null
          context.emit('missingSupplier')
      })     

      watchEffect((onInvalidate) => {
        // unsubscribe from the realtime listner
        onInvalidate(() => unsub())
      })

      return { supplier, hasLocation, locationInfo }
    }
}
</script>