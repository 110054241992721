<template>
  <div class="box mt-5">
    <div class="columns has-text-centered">
      <div class="column">
        <h3 class="title is-5"><b>Pending Collection Request</b></h3>
      </div>
      <div class="column">
        <button
          v-if="!documents.length"
          class="button is-rounded is-success"
          @click="showModal = true"
        >
          Create Collection Request
        </button>
        <button
          v-if="documents.length"
          class="button is-rounded is-success"
          @click="showContactModal = true"
        >
          Contact Us
        </button>
      </div>
    </div>
    <div v-if="!documents || !documents.length">
      No pending collection requests for next 7 days
    </div>
    <div
      class="card"
      v-else
      v-for="request in documents"
      :key="request.id"
      :class="{ 'has-background-success-light': request.status == 'PLANNED' }"
    >
      <div class="card-content">
        <div class="content">
          <div class="columns is-mobile is-vcentered">
            <div class="column">
              <p><b>ID:</b> {{ request.id }}</p>
              <p><b>Requested By:</b> {{ request.requestedBy }}</p>
              <p>
                <b>Requested Date:</b>
                {{ request.requestedDate.toDate().toDateString() }}
              </p>
              <p><b>Status:</b> {{ request.status }}</p>
              <p><b>Additional requirements:</b></p>
              <div class="ml-4">
                <p>
                  <b>Replace Container:</b>
                  {{ request.replaceContainer ? "Yes" : "No" }}
                </p>
                <p>
                  <b>Additional Container:</b>
                  {{ request.replaceContainer ? "Yes" : "No" }}
                </p>
              </div>
            </div>
            <div
              class="column is-5 has-text-right"
              v-if="request.status == 'REQUESTED'"
            >
              <button
                class="button is-danger ml-2"
                @click="deleteCollectionRequest(request.id)"
              >
                &cross;
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="box notification is-warning">
        <div class="has-text-left">
          Creating a new collection request will overwite any exsisting
          collection request
        </div>
      </div>
    </div>
  </div>

  <!-- Modals -->
  <CollectionRequestModal
    :showModal="showModal"
    :supplierid="supplierid"
    :locationid="locationid"
    @close="showModal = false"
  />

  <ContactUsModal
    :showModal="showContactModal"
    @close="showContactModal = false"
  />
</template>

<script>
import { db } from "@/firebase/config";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  where,
} from "@firebase/firestore";
import { ref, watchEffect } from "vue";
import CollectionRequestModal from "./CollectionRequestModal.vue";
import ContactUsModal from "./ContactUsModal.vue";
export default {
  components: { CollectionRequestModal, ContactUsModal },
  props: ["supplierid", "locationid"],
  setup(props) {
    const documents = ref([]);
    const showModal = ref(false);
    const showContactModal = ref(false);

    // collection reference
    let colRef = collection(db, "collectionRequests");
    colRef = query(
      colRef,
      where("supplierId", "==", props.supplierid),
      where("locationId", "==", parseInt(props.locationid))
    );

    const unsub = onSnapshot(colRef, (snapshot) => {
      let results = [];
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      snapshot.docs.forEach((doc) => {
        // console.log(doc.data());
        // console.log(today);
        // console.log(doc.data().requestedDate.toDate());
        // console.log(doc.data().requestedDate.toDate() >= today);

        if (doc.data().requestedDate.toDate() >= today) {
          console.log("Found new record", doc.data());
          // console.log(Timestamp.now());
          results.push({ ...doc.data(), id: doc.id });
        }
        console.log(results);
      });

      // update values
      documents.value = results;
    });

    watchEffect((onInvalidate) => {
      onInvalidate(() => unsub());
    });

    const deleteCollectionRequest = (id) => {
      deleteDoc(doc(collection(db, "collectionRequests"), id));
    };

    return { documents, deleteCollectionRequest, showModal, showContactModal };
  },
};
</script>