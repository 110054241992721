<template>
  <!-- Heading Section -->
  <div class="section has-text-centered">
    <!-- <div class="box"> -->
    <div class="title">
      <figure class="is-image by-3">
        <img
          src="https://d820a6sl534t.cloudfront.net/uploads/logo/de8ba9e3-4590-4681-936d-e05229205e06/large_673ead90-cdeb-46b4-a433-c28999ae8900.png"
        />
      </figure>
      <h1 class="title">Supplier Collection Request</h1>
      <!-- </div> -->
    </div>
  </div>
</template>